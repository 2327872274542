import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


export default class Map {

    constructor($view) {

        this.$view = $view;
        this.$mapSearch = $('.js-map-search');
        this.$mapSearch.on('click', () => {
            this.centerMap();
        });

        this.$optionMasquageNumeroHaie = $('.js-map-option-visibilityhaie');
        this.$optionMasquageNumeroHaie.on('click', () => {
            this.toggleVisibilityNumeroHaie();
        });

        this.map = null;
        this.group = null;
        this.lastZoom = null;
        this.tooltipThreshold = 15;

        this.urlgeojson = this.$view.data('urlgeojson');
        this.urlmodalview = this.$view.data('urlmodalview');
        this.urlmodaledit = this.$view.data('urlmodaledit');
        this.urlmodalold = this.$view.data('urlmodalold');
        this.longitude = this.$view.data('longitude');
        this.latitude = this.$view.data('latitude');
        this.envProd = this.$view.data('envprod');
        this.polylines = [];
        this.lines = [];

        this.ApiKey = this.envProd === "True" ? "essentiels" : "essentiels";
        this.initMapElements();

        //if (this.envProd === "True") {
        //    console.log("Choix 1");
        //    Gp.Services.getConfig({
        //        apiKey: "choisirgeoportail", //"3fmdnitj95j98f5c382x9n1m",
        //        onSuccess: () => {
        //            console.log("Passage 3")
        //            this.initMapElements();
        //        },
        //    });
        //}
        //else {
        //    console.log("Choix 2");
        //    this.initMapElements();
        //}



        $(window).on('map.closeModal', (e, json) => {
            this.masquerModal();
            this.miseajourInfoWindow(json);
            this.miseajourPolyligne(json.haieId);

            // Si le chantier est cloturé, on modifie la couleur du polyligne associe
            if (json.estCloture === "True") {
                this.miseajourPolyligne(json.haieId);
            }
        });
    }

    toggleVisibilityNumeroHaie() {
        var cheked = this.$optionMasquageNumeroHaie.is(":checked");
        if (cheked)
            this.hideTooltipHaies();
        else
            this.showTooltipHaies();
    }

    showTooltipHaies() {
        this.map.eachLayer(function (l) {
            if (l.getTooltip()) {
                let tooltip = l.getTooltip();
                l.unbindTooltip().bindTooltip(tooltip, {
                    permanent: true
                })
            }
        });
    }

    hideTooltipHaies() {
        this.map.eachLayer(function (l) {
            if (l.getTooltip()) {
                let tooltip = l.getTooltip();
                l.unbindTooltip().bindTooltip(tooltip, {
                    permanent: false
                })
            }
        })
    }

    zoomEvent() {
        let zoom = this.map.getZoom();
        if (zoom < this.tooltipThreshold && (!this.lastZoom || this.lastZoom >= this.tooltipThreshold)) {
            this.hideTooltipHaies();
        }
        else if (zoom >= this.tooltipThreshold && (!this.lastZoom || this.lastZoom < this.tooltipThreshold)) {
            this.showTooltipHaies();
        }
        this.lastZoom = zoom;
    }

    centerMap() {
        //Si aucune lignes n'a été tracée, on centrer sur les coordonnées du contact
        if (this.polylines.length > 0) {

            this.map.fitBounds(this.group.getBounds());
        }
        else {
            this.map.setView(new L.LatLng(this.longitude, this.latitude), 15);
        }
    }

    zoomToHaie(codeLabel) {
        // Récupère la haie par son code label
        let polyline = this.polylines.find(elem => elem.properties.CodeLabel === codeLabel);
        if (typeof polyline != "undefined")
            this.map.fitBounds(polyline.lignes);
        else
            this.map.setView(new L.LatLng(this.longitude, this.latitude), 15);
    }

    miseajourPolyligne(haieId) {
        this.lines.forEach(line => {
            if (line.haieid === haieId) {
                line.color = '#77B73D';
            }
        });
    }

    miseajourInfoWindow(data) {

        this.$view.find(".js-value-anneeexploitation").html(data.anneeExploitation);
        this.$view.find(".js-value-anneeentretien").html(data.anneeProchainEntretien);
        this.$view.find(".js-value-typologiehaie").html(data.typologie);
        this.$view.find(".js-value-tonnage").html(data.tonnage);

        this.lines.forEach(line => {

            if (line.haieid === data.HaieId) {
                line._popup._content = this.initPopupContent(data);
            }
        });
    }

    miseajourModal(html) {
        $('.js-modal-content').html(html);
        $('.js-modal').modal();
        $('.js-modal').modal('handleUpdate');
    }

    masquerModal() {
        $('.js-modal').modal('hide');
    }

    bind() {

        let $body = $('body');

        $body.on('click', '.js-toggle-view', (e) => {
            e.preventDefault();
            $.ajax({
                url: this.urlmodalview + '/' + e.currentTarget.dataset.id,
                type: "GET",
                dataType: "Html",
                async: false,
                context: this,
                success: function (result) {
                    this.miseajourModal(result);
                    window.Kernel.mountComponents($('.js-modal-content'));
                }
            });

        });

        $body.on('click', '.js-toggle-edit', (e) => {

            e.preventDefault();
            $.ajax({
                url: this.urlmodaledit + '?id=' + e.currentTarget.dataset.id,
                type: "GET",
                dataType: "Html",
                async: false,
                context: this,
                success: function (result) {
                    this.miseajourModal(result);
                    window.Kernel.mountComponents($('.js-modal-content'));
                }
            });

        });

        $body.on('click', '.js-toggle-add', (e) => {

            e.preventDefault();

            let url = this.urlmodaledit + '?id=' + e.currentTarget.dataset.id + '&isAjout=True';
            if (e.currentTarget.dataset.confirmation === "True") {

                swal({
                    title: 'Créer un nouveau chantier?',
                    text: "Vous ne pourrez plus modifier le précédent chantier.",
                    icon: 'warning',
                    context: this,
                    buttons: {
                        cancel: "Annuler",
                        confirm: {
                            text: "Confirmer",
                            value: url
                        }
                    }
                })
                    .then((value) => {
                        if (value !== "" && value !== null) {
                            $.ajax({
                                url: url,
                                type: "GET",
                                dataType: "Html",
                                async: false,
                                context: this,
                                success: function (result) {
                                    this.miseajourModal(result);
                                    window.Kernel.mountComponents($('.js-modal-content'));
                                }
                            });
                        }
                    });
            }
            else {
                $.ajax({
                    url: url,
                    type: "GET",
                    dataType: "Html",
                    async: false,
                    context: this,
                    success: function (result) {
                        this.miseajourModal(result);
                        window.Kernel.mountComponents($('.js-modal-content'));
                    }
                });
            }
        });

    }

    initMapElements() {

        this.bind();
        this.initMap();
        this.initDataGeoJson();
        this.initPolylines();
    }

    initMap() {

        this.map = new L.Map(this.$view[0], { zoomControl: false, crs: L.CRS.EPSG3857 });

        let osm = new L.tileLayer(
            "https://wxs.ign.fr/" + this.ApiKey + "/geoportail/wmts?" +
            "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
            "&STYLE=normal" +
            "&TILEMATRIXSET=PM" +
            "&FORMAT=image/jpeg" +
            "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS" +
            "&TILEMATRIX={z}" +
            "&TILEROW={y}" +
            "&TILECOL={x}",
            {
                minZoom: 0,
                maxZoom: 18,
                attribution: "IGN-F/Geoportail",
                tileSize: 256 // les tuiles du Géooportail font 256x256px
            }
        );

        this.map.addLayer(osm);

        L.control.zoom({
            position: 'bottomleft'
        }).addTo(this.map);

    }

    initDataGeoJson() {
        $.ajax({
            url: this.urlgeojson,
            type: "GET",
            dataType: "Json",
            async: false,
            context: this,
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                let json = JSON.parse(result.geojson);
                this.initDataPolylines(json);

            }, error: function () {
                swal('Erreur encourue lors de la récupération du geoJson');
            }
        });
    }

    initDataPolylines(json) {


        json.features.forEach(feature => {
            let polylines = {
                properties: feature.properties,
                lignes: []
            };
            if (feature.geometry !== undefined && feature.geometry.coordinates !== undefined) {
                feature.geometry.coordinates.forEach(coordinate => {

                    polylines.lignes.push(new L.LatLng(coordinate[1], coordinate[0]));
                });

                this.polylines.push(polylines);
            }
        });
    }

    initPopupContent(properties) {

        let anneeExploitation = properties.anneeExploitation === undefined ? "Aucune" : properties.anneeExploitation;
        let anneeEntretien = properties.anneeProchainEntretien === undefined ? "Non défini" : properties.anneeProchainEntretien;
        let tonnage = properties.tonnage === undefined ? "0.0" : properties.tonnage;
        let popupContent = `
            <div class="infowindow" data-id="`+ properties.HaieId + `">
                <div class="metas">
                    <div class="meta pr-2">
                        <span class="label">Numéro de haie</span>
                        <span class="value">` + properties.CodeLabel + `</span>
                    </div>
                    <div class="meta">
                        <span class="label">Linéaire</span>
                        <span class="value">` + properties.longueur + ` m</span>
                    </div>
                    <div class="meta pr-2">
                        <span class="label">Type de haie</span>
                        <span class="value js-value-typologiehaie">` + properties.typologie + `</span>
                    </div>
                    <div class="meta pr-0">
                        <span class="label">Dernière exploitation</span>
                        <span class="value js-value-anneeexploitation">`+ anneeExploitation + `</span><span class="value"> - </span><span class="value js-value-tonnage">` + tonnage + `</span><span class="value"> t</span>
                        <span class="label pt-2 mt-2" style="border-top: 1px solid #D9D9D9;">Année entretien conseillée</span>
                        <span class="value js-value-anneeentretien">`+ anneeEntretien + `</span>
                    </div>
                </div>
                <div class="cta">
                    <a class="btn btn-primary js-toggle-view" data-id="`+ properties.HaieId + `" href="#"><i class="material-icons">add</i> Voir plus de détails</a>
                </div>
            </div>`;

        return popupContent;
    }

    initPolylines() {

        let polylineColors = ['#FFF', '#77B73D', '#FF9800', '#C62828'];

        let popupOptions = {
            closeButton: false,
            minWidth: 250
        };

        let polylineRenderer = L.canvas({ padding: 0.5, tolerance: 20 });

        this.group = new L.featureGroup();

        this.polylines.forEach(polyline => {
            let color = polylineColors[polyline.properties.priorite];
            let lines = new L.Polyline(polyline.lignes, { color: color, weight: 4, renderer: polylineRenderer, text: "TEST" });
            lines.bindTooltip(polyline.properties.CodeLabel, { permanent: true });
            lines.color = color;
            lines.haieid = polyline.properties.HaieId;

            lines.bindPopup(this.initPopupContent(polyline.properties), popupOptions)
                .on('popupopen', (e) => {
                    e.target.setStyle({
                        color: '#FFF'
                    });
                    this.map.panTo(e.target.getCenter());
                    this.$mapSearch.fadeOut();
                })
                .on('popupclose', (e) => {
                    e.target.setStyle({
                        color: e.target.color
                    });
                    this.$mapSearch.fadeIn();
                });
            lines.addTo(this.group);
            this.map.addLayer(lines);
            this.lines.push(lines);
        });

        this.centerMap();

        this.map.on('zoomend', function () {
            this.zoomEvent();
        }, this);
    }
}