export default class Header {

    constructor($view) {
        this.$view = $view;

        this.$menu = $view.find('.js-nav');
        this.$toggler = $view.find('.js-toggle-nav');

        this.bind();
    }

    bind() {

        this.$toggler.on('click', (e) => {
            e.preventDefault();
            this.$menu.slideToggle();
            this.$toggler.toggleClass('active');
        });

        if ($(window).width() >= 719) {
            this.$toggler.removeClass('active');
        }

        $(window).on('resize', (e) => {
            if ($(window).width() >= 719) {
                this.$toggler.removeClass('active');
            }
        });

    }

}
