export default class PerformancesEconomiques {

    constructor($view) {
        this.$view = $view;
        this.datedebutperformance = this.$view.data('datedebutperformance');
        this.datefinperformance = this.$view.data('datefinperformance');

        this.urlreadperformance = this.$view.data('urlreadperformance');
        this.bind();
    }

    miseAJourPerformancesEconomiques() {

        var dateDebutPerformance = $("#DateDebutPerformance").val().split(/\//g);
        var dateFinPerformance = $("#DateFinPerformance").val().split(/\//g);

        var dateEngDebutPerformance = dateDebutPerformance[1] + '-' + dateDebutPerformance[0] + '-' + dateDebutPerformance[2];
        var dateEngFinPerformance = dateFinPerformance[1] + '-' + dateFinPerformance[0] + '-' + dateFinPerformance[2];

        $.ajax({
            url: this.urlreadperformance + '?DateDebutPerformance=' + dateEngDebutPerformance + '&DateFinPerformance=' + dateEngFinPerformance,
            type: "GET",
            dataType: "Html",
            async: false,
            context: this,
            success: function(result) {
                this.$view.find("#partial-resultatseconomiques").html(result);
            }
        });

         
    }

    bind() {
        $("#DateDebutPerformance").val(this.datedebutperformance);
        $("#DateFinPerformance").val(this.datefinperformance);

        this.$view.on('change', "#DateDebutPerformance", (e) => {
            this.miseAJourPerformancesEconomiques();
        });

        this.$view.on('change', "#DateFinPerformance", (e) => {
            this.miseAJourPerformancesEconomiques();
        });

        this.$view.on('click', ".input-group-text", (e) => {

            var target = $(e.target);
            target.closest(".input-group").find("input").focus();
        });

    }

}
