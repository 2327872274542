require('./scss/main.scss');
require('./js/kendo-ui-license.js');

const $ = require("jquery");
window.jQuery = $;
window.$ = $;

import App from '6tm-components';
window.App = App;

import Globalize from '6tm-globalize';
import '6tm-globalize/src/cultures/fr-FR';

window.Globalize = Globalize;

import toastr from 'toastr';
window.toastr = toastr;

import swal from 'sweetalert';
window.swal = swal;

import JSZip from 'jszip';
window.JSZip = JSZip;

require('bootstrap');

import '@progress/kendo-ui/';
import './js/kendo-ui-license.js';
import '@progress/kendo-ui/js/kendo.grid';

import '@progress/kendo-ui/js/kendo.datepicker';
import '@progress/kendo-ui/js/kendo.timezones.js';
import '@progress/kendo-ui/js/kendo.aspnetmvc.js';

import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR';

const Kernel = require('./js/utils/Kernel');



function resetHeight() {
    if (document.body != null)
        document.body.style.height = window.innerHeight + "px";
}

window.addEventListener("resize", resetHeight);
resetHeight();

/*
bindings API
*/

App.BindLabel = (function (App) {

    function bindTooltips(target) {

        var ttconf = {
            container: 'body',
            html: true
        };

        target.find('[rel="tooltip"]').tooltip(ttconf);
        target.find('[data-toggle="tooltip"]').tooltip(ttconf);
    }

    function bindXHR(target) {

        target.on('click', 'a[data-src="xhr"]', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var url = $(this).attr('href');
            var confirm = $(this).attr('data-confirm');
            if (confirm) {
                swal({
                    title: "Tests", // confirm
                    text: "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                    closeOnConfirm: false
                }, function () {
                    App.Net.GET(url);
                    swal.close();
                });
            } else {
                App.Net.GET(url);
            }
        });

    }

    function bindForm(target) {
        target.on('submit', 'form[data-src="xhr"]', function (e) {
            e.preventDefault();
            App.Net.submit($(this));
        });
    }

    return {
        Page: function () {
            var target = $('body');
            bindXHR(target);
            bindForm(target);
            bindTooltips(target);
        },

        Partial: function (target) {
            bindTooltips(target);
        }
    };
    // END
})(App);

$(document).ready(function () {
    App.Net.options = { initMessages: false }; // interdit le premier load
    App.Bind.Page();

    Globalize.culture('fr-FR');
    // Kendo : mise en FR
    kendo.culture("fr-FR");

    window.Kernel = new Kernel();

    window.Kernel.registerComponent('Map', require('./js/components/Map').default);
    window.Kernel.registerComponent('Header', require('./js/components/Header').default);
    window.Kernel.registerComponent('Chantier', require('./js/components/Chantier').default);
    window.Kernel.registerComponent('PerformancesEconomiques', require('./js/components/PerformancesEconomiques').default);
    window.Kernel.registerComponent('DemandeEngagement', require('./js/components/DemandeEngagement').default);
    window.Kernel.mountComponents($('html'));
});

$(document).ajaxError(function (xhr, props) {
    if (props.status === 500) {
        hideLoader();
    }
});


// Autres

function showLoader() {
    $("#GlobalLoader").show();
}

function hideLoader() {
    $("#GlobalLoader").hide();
}

// Wizard

window.showLoader = showLoader;
window.hideLoader = hideLoader;

// General

$("body").on('click', ".submit-form", function (e) {
    console.log($(this).closest("#form"));
    $(this).closest("#form").submit();
});

$("body").on("change", ".form-numeric", function () {

    $(this).val($(this).val().replace(".", ","));
});