export default class DemandeEngagement {

    constructor($view) {

        this.$view = $view;
        this.totalSteps = $('.step').length;
        this.progressBarDoneWidthSteps = [15, 50, 85]
        this.currentStep = 1
        this.clefsite = this.$view.data('clefsite');
        this.action = this.$view.data('action');

        this.bind();
        window.DemandeEngagement = this;
    }

    moveToStep(step) {
        let steps = $('.step');
        let newStep = steps.eq(step - 1);
        let autresSteps = steps.not(newStep);

        this.currentStep = step;
        this.updateProgress();
        this.updateNavigation();

        newStep.show();
        autresSteps.hide();

    }

    updateNavigation() {
        $(".js-button-prev").toggle(this.currentStep > 1);
        $(".js-button-next").toggle(this.currentStep < this.totalSteps);
        $(".js-button-submit").toggle(this.currentStep == this.totalSteps);
    }

    updateProgress() {
        // Mettre � jour l'indicateur de progression actif
        let stepIndicators = $('.step-indicator');
        let labelStepIndicators = $(".label-step-indicator");
        stepIndicators.removeClass('active');
        labelStepIndicators.removeClass('active');

        let stepIndicatorsActive = stepIndicators.slice(0, this.currentStep);
        stepIndicatorsActive.addClass('active');

        let labelStepIndicatorActive = labelStepIndicators.eq(this.currentStep - 1);
        labelStepIndicatorActive.addClass('active');

        $(".progress-bar-done").css("width", (this.progressBarDoneWidthSteps[this.currentStep - 1]) + "%")
    }

    getToken() {
        grecaptcha.enterprise.ready(async () => {
            grecaptcha.enterprise.execute(this.clefsite, { action: this.action }).then(function (token) {
                $("#Recaptcha").val(token);
            });
        });
    }


    bind() {
        this.moveToStep(this.currentStep);
        this.getToken();
        setInterval(this.getToken, 115000)
        let $body = $('body');

        $body.on('click', ".js-button-next", (e) => {

            e.preventDefault();
            this.moveToStep(this.currentStep + 1);
        });

        $body.on('click', ".js-button-prev", (e) => {

            e.preventDefault();
            this.moveToStep(this.currentStep - 1);
        });
    }
}

