export default class Chantier {

    constructor($view) {
        this.$view = $view;
        this.daterecolte = this.$view.data('daterecolte');
        this.estcloture = this.$view.data('estcloture');
        this.urlcalcultonnageplaquette = this.$view.data('urlcalcultonnageplaquette');
        this.urlcalcultonnageboisoeuvre = this.$view.data('urlcalcultonnageboisoeuvre');
        this.urlcalcultonnageboisbuche = this.$view.data('urlcalcultonnageboisbuche');
        this.urlparametregestionchantier = this.$view.data('urlparametregestionchantier');
        this.formatageLangue = 'fr-FR';
        this.formatagePrix = { style: 'currency', currency: 'EUR' };

        this.estHauteurCoupe = false;
        this.lignesHauteurCoupe = ["js-row-calcul-abattagetelesco", "js-row-calcul-reprisetracteurelevateur" ]

        this.bind();
        this.MiseEnFormeTexteTypeGestion();
    }

    AfficherChampsObligatoiresChantierCloture(checked) {
        if (checked) {
            this.$view.find(".js-mandatoryfield-chantiercloture").show();
        }
        else {
            this.$view.find(".js-mandatoryfield-chantiercloture").hide();
        }
    }

    DisableChamps(readonly) {
        if (readonly) {
            this.$view.find("input").attr("disabled", "");
            this.$view.find(".k-select").hide();
        }
    }

    MiseAJourBlocInformationTarif() {
        let volumePlaquette = (this.$view.find("#VolumePlaquette").val() + "").replace(",", ".");

        // PYT - 09/05/2023 (Ticket 0059937)
        // Pour le moment il n'y a que le tarif plaquette dans le fomulaire de chantier,
        // il faudra prendre en compte les autres volumes dans l'affichage du bloc de tarifs

        // let volumeBoisOeuvre = (this.$view.find("#VolumeBoisOeuvre").val() + "").replace(",", ".");
        // let volumeBoisBuche = (this.$view.find("#VolumeBoisBuche").val() + "").replace(",", ".");

        let displayBlocTarif = volumePlaquette > 0;

        if (displayBlocTarif) {
            this.$view.find("#js-section-tarif").removeClass("d-none")
        }
        else {
            this.$view.find("#js-section-tarif").addClass("d-none")
        }
    }

    MiseAJourTonnagePlaquette() {
        let volume = (this.$view.find("#VolumePlaquette").val() + "").replace(",", ".");
        let typeUnite = this.$view.find("#UnitePlaquette").val();

        let displayTarif = volume > 0;

        if (displayTarif) {
            this.$view.find("#js-section-tarif-plaquette").removeClass("d-none")
        }
        else {

            this.$view.find("#js-section-tarif-plaquette").addClass("d-none")
            this.$view.find("#TarifPlaquette").val("");
        }

        this.MiseAJourBlocInformationTarif();

        $.ajax({
            url: this.urlcalcultonnageplaquette + '?typeUnite=' + typeUnite + '&volume=' + volume,
            type: "GET",
            dataType: "Json",
            async: false,
            context: this,
            success: function (result) {
                result.tonnage = (result.tonnage + "").replace(".", ",");
                this.$view.find(".js-value-tonnageplaquette").html(result.tonnage);
                this.$view.find("#TonnagePlaquette").val(result.tonnage);
            }
        });
    }

    MiseAJourTonnageBoisOeuvre() {

        let volume = (this.$view.find("#VolumeBoisOeuvre").val() + "").replace(",", ".");
        let typeUnite = this.$view.find("#UniteBoisOeuvre").val();

        this.MiseAJourBlocInformationTarif();

        $.ajax({
            url: this.urlcalcultonnageboisoeuvre + '?typeUnite=' + typeUnite + '&volume=' + volume,
            type: "GET",
            dataType: "Json",
            async: false,
            context: this,
            success: function (result) {
                result.tonnage = (result.tonnage + "").replace(".", ",");
                this.$view.find(".js-value-tonnageboisoeuvre").html(result.tonnage);
                this.$view.find("#TonnageBoisOeuvre").val(result.tonnage);
            }
        });
    }

    MiseAjourTonnageBoisBuche() {

        let volume = (this.$view.find("#VolumeBoisBuche").val() + "").replace(",", ".");
        let typeUnite = this.$view.find("#UniteBoisBuche").val();

        this.MiseAJourBlocInformationTarif();

        $.ajax({
            url: this.urlcalcultonnageboisbuche + '?typeUnite=' + typeUnite + '&volume=' + volume,
            type: "GET",
            dataType: "Json",
            async: false,
            context: this,
            success: function (result) {
                result.tonnage = (result.tonnage + "").replace(".", ",");
                this.$view.find(".js-value-tonnageboisbuche").html(result.tonnage);
                this.$view.find("#TonnageBoisBuche").val(result.tonnage);
            }
        });

    }

    MiseAjourAffichageGroupesTarifs() {
        // Pour chaque element de classe js-row-groupe
        this.$view.find(".js-row-groupe").each((i, e) => {

            //On r�cup�re la classe ayant un nom similaire avec une pr�cision suppl�mentaire
            let classes = $(e).attr('class').split(' ');
            let jsRowGroupClass = classes.find(item => item.toLowerCase().indexOf("js-row-groupe-") >= 0);

            let elemJsRowClass = jsRowGroupClass.split('-');
            let jsRowClass = elemJsRowClass[elemJsRowClass.length - 1];

            // On r�cup�re les �l�ments js-row-ligne appartenant au m�me groupe que la pr�cision r�cup�r�e pr�c�demment.
            // Si ils ont toutes la classe d-none (display none), alors on affiche pas l'�l�ment js-row-groupe attitr�
            let rows = this.$view.find(".js-row-ligne-" + jsRowClass);
            let result = $(rows).not(".d-none").length;

            if (result == 0) {
                $(e).addClass("d-none")
            }
            else {
                $(e).removeClass("d-none")
            }

        });
    }



    MiseAjourAffichageLignesTarifs() {
        // Pour chaque element de classe js-dureeinitiale
        this.$view.find(".js-dureeinitiale").each((i, e) => {

            if ($(e.currentTarget).val() === "") {
                $(e.currentTarget).val(0);
            }

            //On r�cup�re la classe ayant un nom similaire avec une pr�cision suppl�mentaire
            let classes = $(e).attr('class').split(' ');
            let jsDureeInitialeClass = classes.find(item => item.toLowerCase().indexOf("js-dureeinitiale-") >= 0);
            let value = $(e).val();



            let elemJsDureeInitialeClass = jsDureeInitialeClass.split('-');
            let jsRowClass = elemJsDureeInitialeClass[elemJsDureeInitialeClass.length - 1];

            // On r�cup�re l'�l�ment js-row-calcul appartenant au m�me groupe que la pr�cision r�cup�r�e pr�c�demment.
            // Si la dur�e initiale contenu dans l'input est a 0, alors on affiche pas la lign� js-row-calcul attitr�
            let nomRowClass = "js-row-calcul-" + jsRowClass;
            let row = this.$view.find("." + nomRowClass);
            let estLigneHauteurCoupeAMasquer = this.lignesHauteurCoupe.indexOf(nomRowClass) >= 0 && !this.estHauteurCoupe;

            if (value == 0 || (estLigneHauteurCoupeAMasquer)) {
                $(row).addClass("d-none")
            }
            else {
                $(row).removeClass("d-none")
            }

        });

        this.MiseAjourAffichageGroupesTarifs();
    }

    Formatage(localStr, number, parameters = null) {
        if (number === null || typeof (number) === 'undefined')
            return 0;

        if (typeof (number) === 'number')
            return number;

        return new Intl.NumberFormat(localStr, parameters).format(number)
    }

    FormatageNombreToString(number) {
        if (number === null || typeof (number) === 'undefined')
            return 0;

        return (number + "").replace(".", ",")
    }

    MiseEnFormeTexteTypeGestion() {
        let typeGestionChantier = $("#switchTypeGestion").is(":checked") ? 1 : 0;
        switch (typeGestionChantier)
        {
            case 0:
                this.$view.find(".js-type-gestion-manuelle").addClass("font-weight-bold").addClass("element-active");
                this.$view.find(".js-type-gestion-mecanique").removeClass("font-weight-bold").removeClass("element-active");
                break;

            case 1:
                this.$view.find(".js-type-gestion-manuelle").removeClass("font-weight-bold").removeClass("element-active");
                this.$view.find(".js-type-gestion-mecanique").addClass("font-weight-bold").addClass("element-active");
                break;
            default:
                break;
        }
    }

    UpdateDataKendo(selector, typekendoElement, value) {

        let kendoElement = $(selector).data(typekendoElement);
        kendoElement.value(value);
        this.UpdateTatif(kendoElement.element);
    }

    UpdateTatif(target) {
        if ($(target).val() === "") {
            $(target).val(0);
        }

        let classes = $(target).attr('class').split(' ');
        let jsCalcClass = classes.find(item => item.toLowerCase().indexOf("js-calcul-") >= 0);
        let result = 0;

        let views = this.$view.find("input." + jsCalcClass);
        let compteurInput = 0;
        views.each(function () {

            if ($(this)[0].id === "")
                return;

            let value = parseFloat($(this).val().replace(",", "."));
            result = compteurInput === 0
                ? value
                : result * value;

            compteurInput++;

        });

        result = !isNaN(result)
            ? result.toFixed(2)
            : 0.0;

        let elemJsCalcClass = jsCalcClass.split('-');
        let jsResultClass = elemJsCalcClass[elemJsCalcClass.length - 1];

        this.$view.find(".js-result-" + jsResultClass).html(this.Formatage(this.formatageLangue, result, this.formatagePrix));
        this.$view.find(".js-value-" + jsResultClass).val((result + "").replace(".", ","));
    }

    bind() {
        this.estHauteurCoupe = $("#EstCoupeHauteur").is(":checked");
        this.MiseAjourAffichageLignesTarifs();
        this.AfficherChampsObligatoiresChantierCloture(this.estcloture === 'True');

        this.$view.on('click', "#EstCloture", (e) => {
            this.AfficherChampsObligatoiresChantierCloture($(e.currentTarget).is(":checked"));
        });

        this.$view.on('change', ".js-enum-uniteplaquete", (e) => {
            this.$view.find("#UnitePlaquette").val($(e.currentTarget).val());
            this.MiseAJourTonnagePlaquette();
        });

        this.$view.on('change', ".js-enum-uniteboisoeuvre", (e) => {
            this.$view.find("#UniteBoisOeuvre").val($(e.currentTarget).val());
            this.MiseAJourTonnageBoisOeuvre();
        });

        this.$view.on('change', ".js-enum-uniteboisbuche", (e) => {
            this.$view.find("#UniteBoisBuche").val($(e.currentTarget).val());
            this.MiseAjourTonnageBoisBuche();
        });

        this.$view.on('change', "#VolumePlaquette", (e) => {
            this.MiseAJourTonnagePlaquette();
        });

        this.$view.on('change', "#VolumeBoisOeuvre", (e) => {
            this.MiseAJourTonnageBoisOeuvre();
        });

        this.$view.on('change', "#VolumeBoisBuche", (e) => {
            this.MiseAjourTonnageBoisBuche();
        });

        this.$view.on('change', "input.js-tarif", (e) => {
            this.UpdateTatif(e.currentTarget);
        });

        this.$view.on('change', ".js-changement-typegestion", (e) => {

            let typeGestionChantier = $("#switchTypeGestion").is(":checked") ? 1 : 0;
            let longueurHaie = $("#HaieLongueur").val();
            this.MiseEnFormeTexteTypeGestion();
            $.ajax({
                url: this.urlparametregestionchantier + '?typeGestionChantier=' + typeGestionChantier + '&longueurHaie=' + longueurHaie,
                type: "GET",
                dataType: "Json",
                async: false,
                context: this,
                success: function (result) {

                    $("#ChantierTarif_DureeInitialeAbattageMainOeuvre").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeAbattageMainOeuvre));
                    $("#ChantierTarif_DureeInitialeAbattageTronconneuse").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeAbattageTronconneuse));
                    $("#ChantierTarif_DureeInitialeAbattageTeteAbattage").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeAbattageTeteAbattage));
                    $("#ChantierTarif_DureeInitialeAbattageOptionInterventionTelesco").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeAbattageOptionInterventionTelesco));
                    $("#ChantierTarif_DureeInitialeDebardageMainOeuvre").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeTracteurAvecFourche));
                    $("#ChantierTarif_DureeInitialeTracteurAvecFourche").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeTracteurAvecFourche));
                    $("#ChantierTarif_DureeInitialeRepriseMainOeuvre").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeRepriseMainOeuvre));
                    $("#ChantierTarif_DureeInitialeRepriseTronconneuse").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeRepriseTronconneuse));
                    $("#ChantierTarif_DureeInitialeRepriseTracteurElevateur").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeRepriseTracteurElevateur));
                    $("#ChantierTarif_DureeInitialeDechiquetageGrappin").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeDechiquetageGrappin));
                    $("#ChantierTarif_DureeInitialeTransportMainOeuvre").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeTransportMainOeuvre));
                    $("#ChantierTarif_DureeInitialeTransportTracteurRemorque").val(this.FormatageNombreToString(result.chantierTarif.DureeInitialeTransportTracteurRemorque));

                    this.UpdateDataKendo("#ChantierTarif_DureeAbattageMainOeuvre", 'kendoNumericTextBox', result.chantierTarif.DureeAbattageMainOeuvre);
                    this.UpdateDataKendo("#ChantierTarif_DureeAbattageTronconneuse", 'kendoNumericTextBox', result.chantierTarif.DureeAbattageTronconneuse);
                    this.UpdateDataKendo("#ChantierTarif_DureeAbattageTeteAbattage", 'kendoNumericTextBox', result.chantierTarif.DureeAbattageTeteAbattage);
                    this.UpdateDataKendo("#ChantierTarif_DureeAbattageOptionInterventionTelesco", 'kendoNumericTextBox', result.chantierTarif.DureeAbattageOptionInterventionTelesco);

                    this.UpdateDataKendo("#ChantierTarif_DureeDebardageMainOeuvre", 'kendoNumericTextBox', result.chantierTarif.DureeDebardageMainOeuvre);
                    this.UpdateDataKendo("#ChantierTarif_DureeTracteurAvecFourche", 'kendoNumericTextBox', result.chantierTarif.DureeTracteurAvecFourche);
                    this.UpdateDataKendo("#ChantierTarif_DureeRepriseMainOeuvre", 'kendoNumericTextBox', result.chantierTarif.DureeRepriseMainOeuvre);
                    this.UpdateDataKendo("#ChantierTarif_DureeRepriseTronconneuse", 'kendoNumericTextBox', result.chantierTarif.DureeRepriseTronconneuse);

                    this.UpdateDataKendo("#ChantierTarif_DureeRepriseTracteurElevateur", 'kendoNumericTextBox', result.chantierTarif.DureeRepriseTracteurElevateur);
                    this.UpdateDataKendo("#ChantierTarif_DureeDechiquetageGrappin", 'kendoNumericTextBox', result.chantierTarif.DureeDechiquetageGrappin);
                    this.UpdateDataKendo("#ChantierTarif_DureeTransportMainOeuvre", 'kendoNumericTextBox', result.chantierTarif.DureeTransportMainOeuvre);
                    this.UpdateDataKendo("#ChantierTarif_DureeTransportTracteurRemorque", 'kendoNumericTextBox', result.chantierTarif.DureeTransportTracteurRemorque);

                    this.MiseAjourAffichageLignesTarifs();
                }
            });
        });

        this.$view.on('change', ".js-changement-coupehauteur", (e) => {
            this.estHauteurCoupe = $("#EstCoupeHauteur").is(":checked");
            this.MiseAjourAffichageLignesTarifs();
        });

        this.$view.on('click', ".input-group-text", (e) => {

            let target = $(e.target);
            target.closest(".input-group").find("input").focus();
        });



    }

}
